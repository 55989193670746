/* Removed background-color from .cur-interview-table */
.App {
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  align-items: center; /* Center child elements horizontally */
  padding: 10px;
  margin-top: 0;
  padding-top: 0;
  max-width: 1200px;
  min-height: 100vh; /* Use full viewport height */
  margin: auto;
  justify-content: flex-start;
  background-color: #282828; /* Moved background-color here */
  color: #fff;
  margin: 1em auto; /* Remove top margin if any, and center align the app */
  /* font-family: 'Monopoly', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif;
  font-family: 'Inter', sans-serif; */
  font-family: 'Roboto', sans-serif;
  font-size: 1.4em;
}

body {
  background-color: #282828; /* This will ensure the entire page has the same background */
}

.circular-progress {
  width: 200px; /* Added px to define units */
  height: 200px; /* Added px to define units */
}

.easy-progress .p-progressbar .p-progressbar-bg {
  background-color: #294d35 !important;
}

.medium-progress .p-progressbar .p-progressbar-bg {
  background-color: #5e4e25 !important;
}

.hard-progress .p-progressbar .p-progressbar-bg {
  background-color: #5a302f !important;
}

.cur-int-title {
  text-align: center;
}

.add-new-interview {
  width: 80%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  text-align: center; 
}

.add-new-interview button {
  align-items: center;
  margin: auto;
  width: 30%;
}

a {
  cursor: pointer;
  text-decoration: underline;
  color: #fca311;
  font-weight: 400;
  text-align: center;
  display: block;
  /* margin-top: 1rem; */
}
/* If you're using specific classes for your DataTable, prefix them to increase specificity */
.customTable .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.2rem;
  line-height: 1.25;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
}

.widget *,
.component * {
  background-color: #282828;
  color: white;
  max-width: 1200px;
}
.widget {
  position: relative;
}

.problem-link {
  /* display: block; Make the link a block-level element to fill the table cell */
  color: #fca311;
  text-decoration: none;
  cursor: pointer;
  text-align: left; /* Align the text to the left */
  padding: 0;
  min-width: 100%;
}

.problem-link:hover {
  text-decoration: underline;
}

.p-chips-token {
  border: 2px solid #ffffff;
}

.p-dialog *,
.p-sidebar * {
  background-color: #282828;
  color: white;
}

.customTable * {
  background-color: #2e2e2e;
  color: white;
  padding: 5px;
}

.p-button {
  color: white;
}

.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  gap: 25px;
}

.mode-switch .p-inputswitch-checked .p-inputswitch-slider {
  background-color: #ffa116 !important; /* Teal color, use any color you want */
}

/* Optional: Change the background color when the switch is off */
.mode-switch .p-inputswitch-slider {
  background-color: #4a4a4a !important; /* Some color for the off state */
}

.stats-container {
  background-color: #2e2e2e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25rem; /* Proportional padding */
  border-radius: 6px;
  width: min(35vw, 500px); /* Width relative to the viewport width */
  height: auto; /* Height to fit content */
  margin: 0.5rem; /* Proportional margin */
  box-shadow: 5px 5px #ffa216f3;
  max-width: 600px; /* Maximum width */
  min-width: 500px; /* Minimum width */
  max-height: 250px; /* Maximum height */
  min-height: 150px; /* Minimum height */
  transition: transform 0.2 ease;
  position: relative; /* Add this line to make it a relative container */
}

.stats-container:hover {
  scale: 1.01;
}

.chipsContainer {
  width: 100%;
}
/* Adjust font size using viewport width for scaling or use em/rem for better scaling */
.stats-container h2,
.stats-container p,
.stats-container a {
  font-size: 1.1vw;
}

.none-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25rem; /* Proportional padding */
  border-radius: 6px;
  width: min(35vw, 500px); /* Width relative to the viewport width */
  height: auto; /* Height to fit content */
  margin: 0.5rem; /* Proportional margin */
  max-width: 600px; /* Maximum width */
  min-width: 500px; /* Minimum width */
  max-height: 250px; /* Maximum height */
  min-height: 150px; /* Minimum height */
  transition: transform 0.2 ease;
}

/* Media queries can also be used to set minimum font sizes at smaller screen widths */
@media (max-width: 600px) {
  .stats-container h2,
  .stats-container p,
  .stats-container a {
    font-size: 12px; /* Minimum font size */
  }
}

.settingsPage {
  width: 100%;
}
.settingsSideBar {
  width: 400px;
}

.dropdownSelector {
  width: 60%;
}

button {
  /* border: none; */
}

.addUserToLeaderboardButton {
  position: absolute; /* Position the button absolutely within the relative container */
  top: 10px; /* Adjust this value as needed */
  left: 10px; /* Adjust this value as needed */
  z-index: 10; /* Ensure it's above other elements */
}

.cur-buttons {
  position: absolute; /* Position the button absolutely within the relative container */
  top: 5px; /* Adjust this value as needed */
  right: 0px; /* Adjust this value as needed */
  z-index: 10; /* Ensure it's above other elements */
}

.headerCard {
  width: 100%; /* Take full width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; /* Align items to the start */
  /* padding: 0.5rem 0.5rem 0.5rem 2rem; */
  background-color: #282828; /* Keep your header background color */
  /* margin-top: 0; Set to 0 or a smaller value to move the header up */
  margin-bottom: 2em;
}
.dateTimeContainer {
  font-size: 1.5em;
  text-align: center;
  /* margin-right: 1em; */
}

/* If you can apply a custom class directly to the InputText component */
.customInputText {
  width: 100%; /* Ensures the input element takes the full width of its parent */
  /* flex-grow: 1; Let the search bar grow to fill space */
  background-color: #2e2e2e;
  border: 1px solid #2e2e2e; /* Replace with your desired border color */
}

.searchBarContainer {
  width: 60%;
  min-width: 400px; /* Minimum width */
  /* border: 1px solid #ffa116; Replace with your desired border color */
}

.p-input-icon-left {
  width: 100%;
}

.p-datatable .p-datatable-tbody * {
  height: 24px !important;
}

.edit .p-dialog-header {
  height: 0px;
  margin: 0px;
}

.componentSettingsButton {
  display: none; /* Hide button by default */
  /* Other styles for the button */
  position: absolute; /* Position the button absolutely within the relative container */
  top: 10px; /* Adjust this value as needed */
  left: 10px; /* Adjust this value as needed */
  z-index: 10; /* Ensure it's above other elements */
}

.stats-container:hover .componentSettingsButton {
  display: block; /* Show button when hovering over stats-container */
}

.cache-notification-icon {
  /* Other styles for the button */
  position: absolute; /* Position the button absolutely within the relative container */
  top: 10px; /* Adjust this value as needed */
  right: 10px; /* Adjust this value as needed */
  z-index: 10; /* Ensure it's above other elements */
  color: #ef4743; 
}

